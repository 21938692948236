import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent {
  PaymentReference: any;
  Amount: any;
  Status: any;
  Currency: any;
  InvoiceId: any;
  pymntRspData: any;
  data: any;
  token: any;
  constructor(private route: ActivatedRoute,
    private eventServices : EventsService,
    private spinner : NgxSpinnerService,
    private commonService : CommonService,
    private router: Router) {}

  ngOnInit() {
        // null BehaviorSubject data
        this.commonService.registrationSubmitData.next(null);
        this.commonService.attendeecartdDatasubmit.next(null);
        this.commonService.groupRegistrationFormSubject.next(null);
        this.commonService.registrationFormSubject.next(null);

    this.spinner.show();
    this.data = this.route.snapshot.queryParamMap.get('Type');
    this.token = this.route.snapshot.queryParamMap.get('Token');
    this.Amount = this.route.snapshot.queryParamMap.get('Amount');
    this.Status = this.route.snapshot.queryParamMap.get('Status');
    this.Currency = this.route.snapshot.queryParamMap.get('Currency');
    this.InvoiceId = this.route.snapshot.queryParamMap.get('InvoiceId');
    this.PaymentReference = this.route.snapshot.queryParamMap.get('PaymentReference');
   
    let obj = {
      data: this.data,
      token : this.token
    }
    // if (!+sessionStorage.getItem('isClaimed') && !+sessionStorage.getItem('isCrm')) {
    //   this.router.navigate(['/unauthorized']);
    // }
    const isClaimed = sessionStorage.getItem('isClaimed');
    const isCrm = sessionStorage.getItem('isCrm');
    
    if ((isClaimed != '0') && (isCrm != '0')) {
      this.router.navigate(['/unauthorized']);
    }
    this.commonService.crmUrlData.next(obj);
    this.GetInvoiceDetailsByInvoiceId()
  }

  GetInvoiceDetailsByInvoiceId(){
    this.eventServices.GetInvoiceDetailsByInvoiceId(this.InvoiceId).subscribe((invoiceResp:any)=>{
      if(invoiceResp.isSuccessful == true){
        this.pymntRspData = invoiceResp.data
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
    })
  }
  back(){
    this.router.navigate([''])
  }
}
