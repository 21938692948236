<div class="d-flex justify-content-between align-items-center">
    <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <p style="color: white"> Please Wait... </p>
    </ngx-spinner>
</div>

<div class="container bradcum-max-width" *ngIf="!data">
    <div class="row py-2 d-flex justify-content-center">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="#">Business NSW</a></li>
                <li class="breadcrumb-item"><a routerLink="">Event</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/event-details', eventDetailsId]">Event Details</a></li>
                <li class="breadcrumb-item"> <a [routerLink]="['/event-registration', eventDetailsId]"
                        [queryParams]="{ value: value, seat: seat }">
                        Event Registration
                    </a> </li>
                <li *ngIf="value=='Group'" class="breadcrumb-item">
                    Group Attendee
                </li>
            </ol>
        </nav>
    </div>
</div>
<div class=" card-bg-color my-2 p-2">
    <div class="row">
        <div class="col-6">
            <div class="card card  rounded-xl shadow-lg p-4 my-4 " *ngIf="isShow">
                <form [formGroup]="groupRegisterEventForm" class="example-form">
                    <p class="text-center p-1 font-heading section-title fw-bold">Attendee Detail</p>

                    <div class="row ">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>account_box</mat-icon>
                            </span>
                            <mat-label> First Name</mat-label>
                            <!-- <mat-icon class="fs-4 fw-bold">account_box</mat-icon> -->
                            <input formControlName="firstName" matInput placeholder="" value="">
                            <mat-error *ngIf="groupRegisterEventForm.controls['firstName'].touched &&groupRegisterEventForm.controls['firstName'].invalid
                            || groupRegisterEventForm.controls['firstName'].errors">
                                <small *ngIf="groupRegisterEventForm.controls['firstName'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="groupRegisterEventForm.controls['firstName'].hasError('pattern')">
                                    Enter valid First Name.
                                </small>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>account_box</mat-icon>
                            </span>
                            <mat-label> Last Name</mat-label>
                            <input matInput placeholder="" formControlName="lastName" value="">
                            <mat-error *ngIf="groupRegisterEventForm.controls['lastName'].touched &&groupRegisterEventForm.controls['lastName'].invalid
                                    || groupRegisterEventForm.controls['lastName'].errors">
                                <small *ngIf="groupRegisterEventForm.controls['lastName'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="groupRegisterEventForm.controls['lastName'].hasError('pattern')">
                                    Enter valid Last Name.
                                </small>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix class="astrict">
                                <mat-icon matPrefix> email</mat-icon>
                            </span>
                            <mat-label> Email </mat-label>
                            <input matInput placeholder="" formControlName="email" value="">
                            <mat-error *ngIf="groupRegisterEventForm.controls['email'].touched &&groupRegisterEventForm.controls['email'].invalid
                                    || groupRegisterEventForm.controls['email'].errors">
                                <small *ngIf="groupRegisterEventForm.controls['email'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="groupRegisterEventForm.controls['email'].hasError('pattern')">
                                    Enter valid Email.
                                </small>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>phone</mat-icon>
                            </span>
                            <mat-label> Contact Number</mat-label>
                            <input type="text" matInput placeholder="Contact Number" formControlName="mobileNumber"
                                value="" (keypress)="numberOnly($event)">
                            <mat-error *ngIf="groupRegisterEventForm.controls['mobileNumber'].touched &&groupRegisterEventForm.controls['mobileNumber'].invalid
                                || groupRegisterEventForm.controls['mobileNumber'].errors">
                                <small *ngIf="groupRegisterEventForm.controls['mobileNumber'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="groupRegisterEventForm.controls['mobileNumber'].hasError('pattern')">
                                    Enter valid Contact Number.
                                </small>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div class="row" *ngIf="showSeatFlag || eventDetail?.eventType?.value == 2">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2">
                            <span matPrefix>
                                <mat-icon matPrefix>event_seat</mat-icon>
                            </span>
                            <mat-label> Enter seat number</mat-label>
                            <input type="text" formControlName="seatnumber" matInput placeholder="e.g. Table1 - 1, 2, 3"
                                value="">
                        </mat-form-field>
                        <div class="col-6 text-end p-2 my-2">
                            <button type="button" class="btn-yellow rounded-xl btn shadow-lg fw-bold"
                                (click)="openDialog()" [matTooltip]="'preferred seat'">
                                <mat-icon class="align-middle">airline_seat_recline_normal</mat-icon>
                                Preferred seat
                            </button>
                        </div>
                    </div> -->
                    <div class="row mb-2">
                        <mat-slide-toggle class="custom-toggle" formControlName="isGroupLeader">Are you a Group
                            Leader?</mat-slide-toggle>
                    </div>
                    <div class="row " *ngIf="questionType?.length">
                        <div *ngFor="let question of questionType">
                            <ng-container [ngSwitch]="question.type.label">
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Simple text'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>

                                    <!-- Different input elements based on the question type -->
                                    <input matInput placeholder=""
                                        [formControlName]="question.customRegistrationFieldId"
                                        [value]="question.answer">
                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                        || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Single choice'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>
                                    <mat-select [formControlName]="question.customRegistrationFieldId"
                                        (selectionChange)="checkDependent($event , question)">
                                        <mat-option *ngFor="let option of question.choices" [value]="option">{{
                                            option }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                    || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Multiple choice'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <!-- (selectionChange)="onSelectionChange($event, question.customRegistrationFieldId)" -->
                                    <mat-label>{{ question.text }}</mat-label>
                                    <mat-select [formControlName]="question.customRegistrationFieldId" multiple
                                    #selectCtrl (selectionChange)="checkDependent($event , question)">
                                        <ng-container *ngFor="let option of question.choices">
                                            <mat-option [value]="option" ngDefaultControl>
                                                {{ option }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                    || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <ng-container *ngSwitchCase="'Boolean (yes/no)'">
                                    <span matPrefix>
                                        <mat-icon style="margin-bottom: -1%;
                                      margin-right: 6px;">question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>
                                    <mat-radio-group [formControlName]="question.customRegistrationFieldId"
                                        [value]="question.customRegistrationFieldId"
                                        (change)="checkDependent($event, question)">
                                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                                        <mat-radio-button value="No"
                                            [checked]="question.customRegistrationFieldId == 'No'"
                                            (change)="checkDependent($event, question)">No</mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mt-4">
                        <div class="col-12 col-sm-6 mb-2 mb-sm-0">
                            <button *ngIf="!data" type="button"
                                class="btn-cancel rounded-xl btn shadow-lg btn-green fw-bold" (click)="back()"
                                [matTooltip]="'Back'">
                                <mat-icon class="align-middle">arrow_back</mat-icon>
                                Back
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 text-center text-sm-end">
                            <button *ngIf="addAttendeeFlg" type="button"
                                class="btn-green rounded-xl btn shadow-lg fw-bold"
                                (click)="addAttendee(groupRegisterEventForm)" [matTooltip]="'Add Attendee'"  [disabled]="groupRegisterEventForm.invalid && isSubmitted">
                                Add Attendee <mat-icon class="align-middle">arrow_forward</mat-icon>
                            </button>
                            <button *ngIf="editAttendeeFlag" type="button"
                                class="btn-green rounded-xl btn shadow-lg fw-bold"
                                (click)="updateAttendee(editingIndex)" [matTooltip]="'Update Attendee'">
                                Update <mat-icon class="align-middle">arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        
        <div class="col-6 p-4">
            <div class="card card  rounded-xl shadow-lg">                               

                <div class="p-4">
                    <div class="card-bg-color p-3 rounded-xl shadow-sm" *ngIf="attendees.length <= 0">
                        <p class="text-center fw-bold">No Attendee Found</p>
                    </div>
                    <!-- <br>
                    <div class="alert alert-warning alert-dismissible fade show card-bg-color p-3 rounded-xl shadow-sm" role="alert" *ngIf=" registrationFormData === null">Go back to previous page.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div> -->

                    <!-- <div class="card-bg-color p-3 rounded-xl shadow-sm" *ngIf=" registrationFormData != null">
                        <p class="text-center fw-bold">Please avoid refreshing the page to prevent data loss.</p>
                    </div> -->
                    <!-- Attendee cards -->
                    <div class="card-bg-color my-2 p-2 rounded-xl shadow-lg "
                        *ngFor="let attendee of attendees; let i = index">
                        <div class="d-flex p-1 mx-2">
                            <div class="col-6">
                                <span class="text-end" *ngIf="attendee.isGroupLeader == true">
                                    <span
                                        class="my-2 inline-flex items-center font-bold text-sm px-2 py-0.5 rounded-full tracking-wide bg-red-100 text-red-800">
                                        <span class="pr-0.5 leading-relaxed whitespace-nowrap"><mat-icon
                                                class="align-middle">stars</mat-icon>Group Leader</span>
                                    </span>
                                </span>
                                <p>First Name: {{ attendee.firstName }}</p>
                                <p>Last Name: {{ attendee.lastName }}</p>

                                <p>Email: {{ attendee.email }}</p>


                            </div>

                            <!-- Edit and delete buttons -->
                            <div class="flex col-6 justify-content-end">
                                <div>
                                    <button type="button" class="bg-blue-100 text-blue-800 p-1 mx-1 rounded-full"
                                        (click)="edit(i)" [matTooltip]="'Edit Attendee'">
                                        <mat-icon class="icon-size align-middle">edit</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button type="button" class="bg-red-100 text-red-800 p-1 rounded-full"
                                        (click)="delete(i)" [matTooltip]="'Remove Attendee'">
                                        <mat-icon class="icon-size align-middle">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end" *ngIf="attendees.length > 0 && registrationFormData != null">
                        <button type="button" class="btn-green rounded-xl btn shadow-lg btn-green1 fw-bold"
                            (click)="submit()" [matTooltip]="'Next'">
                            Next <mat-icon class="align-middle">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>