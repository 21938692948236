import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/services/PaymentService';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';
import { ContactUsComponent } from 'src/shared/contact-us/contact-us.component';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  registrationObj: any;
  registrationFormData: any;

  eventDetailsId: any;

  codeOfPromoCode: any;
  selectPassPrice: any;
  percentOfDiscountOfPromoCode: any;
  promocode: any
  totalPrice: any;
  discountAmountInRupees: any;
  passName: any;
  passDetails: any;
  eventName: any;
  attendeeLength: any;
  subTotal: any;
  currencyAUD: any;
  value: any;
  seat: any;
  data: any;
  promoCodeApplied: boolean = false;
  overRideTicket: any
  ticketPrice: any;
  invoiceId: any;
  token: any;
  dialogRef: any;

  constructor(
    private commonService: CommonService,
    private eventService: EventsService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private toaster: ToastrService,
    private _route: ActivatedRoute,
    private router: Router,
    public _dialog: MatDialog
  ) {
    this.eventDetailsId = this._route.snapshot.paramMap.get('id');
    this.GetEventDetailsByEventByEventId();
    // this.commonService.setBreadcrumb('Payment-Details');
    this.value = this._route.snapshot.queryParamMap.get('value');
    this.data = this._route.snapshot.queryParamMap.get('Type');
    this.token = this._route.snapshot.queryParamMap.get('Token');
    // this.seat = parseInt(this._route.snapshot.queryParamMap.get('seat'));
    let obj = {
      data: this.data,
      token: this.token
    }


    const isClaimed = sessionStorage.getItem('isClaimed');
    const isCrm = sessionStorage.getItem('isCrm');

    if ((isClaimed != '0') && (isCrm != '0')) {
      this.router.navigate(['/unauthorized']);
    }
    // if (!+sessionStorage.getItem('isClaimed') && !+sessionStorage.getItem('isCrm')) {
    //   this.router.navigate(['/unauthorized']);
    // }
    this.commonService.crmUrlData.next(obj);
    this.commonService.registrationFormSubject.subscribe(regResp => {
      this.registrationFormData = regResp
      this.attendeeLength = regResp?.attendeeLists?.length || 1
    });

    this.commonService.eventDetail.subscribe(eventresp => {
      this.currencyAUD = eventresp?.currency
      this.seat = parseInt(eventresp?.eventSeatAvailable)
    })
  }
  ngOnInit() {

    this.codeOfPromoCode = localStorage.getItem('codeOfPromoCode');
    this.selectPassPrice = localStorage.getItem('selectPassPrice');
    this.percentOfDiscountOfPromoCode = localStorage.getItem('percentOfDiscountOfPromoCode');
    // this.eventName = localStorage.getItem('eventName');
    this.passName = localStorage.getItem('passName');
    this.passDetails = this.passName + ' ' + this.selectPassPrice
    localStorage.setItem('totalPrice', this.totalPrice);
    this.subTotal = this.selectPassPrice * this.attendeeLength || 0;
    this.ticketPrice = this.selectPassPrice;
    this.totalPrice = this.subTotal
    if (this.data) {
      this.overRideTicket = this.selectPassPrice
      this.ticketOverRide();
    }
    else {
      this.overRideTicket = this.selectPassPrice
    }

    
    if (this.registrationFormData == null) {
      this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }
    
  }
  GetEventDetailsByEventByEventId() {
    this.spinner.show();
    this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
      if (respEventDetails.isSuccessful === true) {
        this.seat = respEventDetails?.data?.eventSeatAvailable;
        this.eventName = respEventDetails?.data?.eventName
        // this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
    this.spinner.hide();
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 && charCode !== 46 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ticketOverRide() {

    this.subTotal = this.overRideTicket * this.attendeeLength || 0;
    this.ticketPrice = this.overRideTicket;
    this.totalPrice = this.subTotal
  }
  promoCodeApply() {
    if (this.codeOfPromoCode === this.promocode) {
      if (this.percentOfDiscountOfPromoCode > 0) {
        this.promoCodeApplied = true;
        const discountAmount = this.subTotal * (this.percentOfDiscountOfPromoCode / 100);
        // Apply the discount to the total price
        this.totalPrice = this.subTotal - discountAmount;
        // Store the discount amount for display
        this.discountAmountInRupees = discountAmount;


      }
    } else {
      this.toaster.error("Invalid promo code");
    }
  }
  clearPromoCode() {
    // Reset discount amount when promo code is cleared
    this.totalPrice = this.subTotal;
    this.discountAmountInRupees = 0;
    this.promoCodeApplied = false
    this.promocode = ''; // Clear the input field
  }

  back() {
    // this.router.navigate(['/event-registration/'+this.eventDetailsId])
    if (this.value == 'Group') {
      this.router.navigate(['/group-attendee/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }
    else {

      this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }
  }
  submitPay() {
    // this.spinner.show();
    this.registrationFormData.totalAmount = parseInt(this.totalPrice);
    this.registrationFormData.ticketPrice = parseFloat(this.ticketPrice);
    this.registrationFormData.promoCodeApplied = this.promoCodeApplied;
    if (this.registrationFormData.totalAmount == 0) {
      this.spinner.show();
      this.eventService.CreateEventRegistration(this.registrationFormData).subscribe((registrationresponce: any) => {

        if (registrationresponce.isSuccessful == true) {
          if (registrationresponce.data && registrationresponce.data.invoiceId) {
            this.invoiceId = registrationresponce.data.invoiceId;
            const body = {
              invoiceId: this.invoiceId,
              stripeReferenceId: "",
              stripePaymentId: "",
              stripePaymentDate: new Date()
            }
            this.paymentService.updatePaymentDetails(body).subscribe((result: any) => {
              if (result.isSuccessful == true) {
                this.spinner.hide();
                this.router.navigate(['/thank-you'], {
                  queryParams: {
                    // PaymentReference: this.tokenObj?.card?.brand,
                    // Amount: this.price,
                    Currency: 'inr',
                    // Status: result.status,
                    InvoiceId: this.invoiceId,
                    Type: this.data
                  },
                });
              }
              else {
                this.spinner.hide();
              }
            });
            this.toaster.success(registrationresponce.messageDetail.message, 'Confirmation', {
            });
            this.spinner.hide();

          }
        }
        else if (registrationresponce.isSuccessful == false) {
          this.spinner.hide();
          this.toaster.error(registrationresponce.messageDetail.message, 'Error', {
          });
        }
      }),
        (error: any) => {
          this.spinner.hide();
          this.toaster.error(error.messageDetail.message, 'Error');
        };;
    }
    else {
      this.router.navigate(['/stripe-payment/' + this.eventDetailsId], { queryParams: { totalPrice1: this.totalPrice, value: this.value, seat: this.seat, Type: this.data, Token: this.token } });
      this.spinner.hide();
    }
  }

  openDialog(): void {
    this.dialogRef = this._dialog.open(ContactUsComponent, {
      disableClose: true,
      width: '900px',
      height: '550px'
    })
  }
  close() {
    this.dialogRef.close(true)
  }

}
