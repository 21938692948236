import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';
import { SeatDialogComponent } from 'src/shared/seat-dialog/seat-dialog.component';

@Component({
    selector: 'app-event-registration',
    templateUrl: './event-registration.component.html',
    styleUrls: ['./event-registration.component.scss']
})
export class EventRegistrationComponent implements OnInit {
    registerEventForm: FormGroup = new FormGroup({});
    selectedPassList: any = [];
    value: any;
    dialogRef: any;
    selectedOption: number = 1;
    selectedPass: any
    seatNumber: any
    submitted: boolean = false;
    seat: any;
    eventDetailsId: any;
    customqueflag: boolean = false;
    customQuetions: any;
    sessionData: any;
    choiseOptions: any;
    customRegistrationQuestion: any;
    optionArray: any;
    passBySessionId: any;
    customRegistrationQuestionId: any;
    noRecordFound: boolean = false;
    norecordFoundMessage: any;
    hideOptions = false;
    questionType: any = [];
    isShow: boolean = false;
    seasons: any = ['Yes', 'No'];
    questionsId: any = [];
    textOnlyRegex: RegExp = /^([a-zA-Z]+[ "'\-\.]*[a-zA-Z]+)+$/;
    emailRegex: RegExp = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    mobileRegex: RegExp = (/^[0-9]{10,13}$/);
    selectPassPrice: any;
    codeOfPromoCode: any;
    percentOfDiscountOfPromoCode: any;
    passName: any;
    showSeatFlag: boolean = false;
    eventDetail: any;
    currencyAUD: any;
    data: any;
    eventDetailsData: any;
    applyPromoCodeId: any;
    registrationFormData: any;
    dynamicQuestions: any = [];
    allQuestionType: any = [];
    token: any;
    isAuth: boolean = false;
    isApiCalled: boolean = false;
    isSubmitted: boolean = false;
    questionList: any = [];
    questionsList: any = [];
    removeQuestionArray: any = [];
    eventModeType: any
    eventTypeValue: any;


    constructor(public router: Router,
        private fb: FormBuilder,
        public commonService: CommonService,
        private cd: ActivatedRoute,
        public _dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private eventService: EventsService,
        private toaster: ToastrService,
        private cdr: ChangeDetectorRef) {



        this.spinner.show()
        this.commonService.registrationSubmitData.subscribe(regResp => {
            this.registrationFormData = regResp
        });
        this.commonService.attendeecartdDatasubmit.next(null);
    }
    ngAfterViewInit(): void {
        // Trigger change detection after view initialization
        this.cdr.detectChanges();
    }


    ngOnInit() {
        this.eventDetailsId = this.cd.snapshot.paramMap.get('id');
        this.value = this.cd.snapshot.queryParamMap.get('value');
        this.data = this.cd.snapshot.queryParamMap.get('Type');
        this.token = this.cd.snapshot.queryParamMap.get('Token');
        // this.seat = parseInt(this.cd.snapshot.queryParamMap.get('seat'));
        this.GetEventDetailsByEventByEventId();
        let obj = {
            data: this.data,
            token: this.token
        }
        if (this.token) {
            this.eventService.GetAuthentication(this.token).subscribe((data: any) => {
                this.isApiCalled = true;
                this.spinner.hide();
                sessionStorage.setItem('isClaimed', data.data.isClaimed ? '1' : '0');
                sessionStorage.setItem('isCrm', '1');
                if (+sessionStorage.getItem('isClaimed')) {
                    this.isAuth = false;
                    this.router.navigate(['/unauthorized']);
                } else {
                    this.isAuth = true;
                    this.callDataApis();
                }
            })
        }
        else if (this.data && !this.token) {

            this.spinner.hide();
            this.isAuth = false;
            this.router.navigate(['/unauthorized']);
        } else {
            sessionStorage.setItem('isClaimed', '0');
            sessionStorage.setItem('isCrm', '0');
            this.callDataApis();
        }
        this.commonService.crmUrlData.next(obj);
    }

    onChangeEventMode(selectedValue: any): void {

        this.eventModeType = selectedValue;
        // console.log('event', this.eventModeType.source.name, this.eventModeType.source.value)

    }


    callDataApis() {
        this.commonService.updateBreadcrumbsData({ seat: this.seat, value: this.value });

        this.registerEventForm = this.fb.group({
            firstName: [null, [Validators.required, Validators.pattern(this.textOnlyRegex)]],
            lastName: [null, [Validators.required, Validators.pattern(this.textOnlyRegex)]],
            email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            mobileNumber: ['', [Validators.required, Validators.pattern(this.mobileRegex)]],
            passId: [null, [Validators.required]],
            selectSession: [null],
            seatnumber: [''],
            isAttendee: [false],
            isGroupLeader: [false],
            registrationMode: [1],


        });

        this.commonService.breadcrumb = "Event-Registration";

        this.GetPassInformationByEventId();
        this.questionDependent()
        this.getEventDetail();



        if (this.registrationFormData) {
            this.registerEventForm.patchValue({
                firstName: this.registrationFormData.firstName,
                lastName: this.registrationFormData.lastName,
                email: this.registrationFormData.email,
                mobileNumber: this.registrationFormData.mobileNumber,
                seatnumber: this.registrationFormData.seatnumber,
                passId: this.registrationFormData.passId,
                selectSession: this.registrationFormData.selectSession,
                isAttendee: this.registrationFormData.isAttendee,
                isGroupLeader: this.registrationFormData.isGroupLeader,
            })
            this.registerEventForm.get('selectSession')?.setValue(this.registrationFormData.selectSession);

        }

    }


    GetEventDetailsByEventByEventId() {
        // if (this.data) {
        this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
            if (respEventDetails.isSuccessful === true) {
                // this.eventTypeValue = respEventDetails.data.eventType.value ;
                if (respEventDetails?.data?.eventType?.value == 2) {
                    this.registerEventForm.get('registrationMode')?.setValue(2)
                    this.eventTypeValue = 1;
                }
                else {
                    this.registerEventForm.get('registrationMode')?.setValue(1)
                }
                this.eventDetailsData = respEventDetails.data;
                this.seat = respEventDetails?.data?.eventSeatAvailable;
                this.commonService.eventDetail.next(this.eventDetailsData);
                if (this.eventDetailsData.eventSeatImage) {
                    this.showSeatFlag = true;
                }
                else {
                    this.showSeatFlag = false;
                }
            } else {
                this.spinner.hide();
            }
        }),
            (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.messageDetail.message, 'Error');
            };

        // }
    }

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 && charCode !== 46 || charCode > 57)) {
            return false;
        }
        return true;
    }


    getEventDetail() {
        this.commonService.eventDetail.subscribe(resp => {
            this.eventDetail = resp;
            this.currencyAUD = resp?.currency
            if (this.eventDetail) {

                if (this.eventDetail?.eventSeatImage) {
                    this.showSeatFlag = true;
                }
                else {
                    this.showSeatFlag = false;
                }
            }
        });
    }

    // Method triggered on slide toggle change
    onSlideToggleChange(event: MatSlideToggleChange) {
        const slideToggleValue = event.checked;

        // Perform actions based on the slide toggle value
        if (slideToggleValue) {
            // Toggle is ON (true)
            // this.registerEventForm.get('seatnumber')?.setValidators([Validators.required]);
            // this.registerEventForm.get('seatnumber')?.updateValueAndValidity();

        } else {

            this.registerEventForm.get('seatnumber')?.setValue('');
            this.registerEventForm.get('seatnumber')?.reset();
            this.registerEventForm.get('seatnumber')?.updateValueAndValidity();
        }
    }



    questionDependent() {

        this.spinner.show();
        this.eventService.GetCustomLogicQuestionByEventId(this.eventDetailsId).subscribe((QuestionResp: any) => {
            if (QuestionResp.isSuccessful == true) {
                if (QuestionResp?.data?.length > 0) {
                    this.allQuestionType = QuestionResp?.data;
                    //  this.dynamicQuestions = QuestionResp?.data;
                    this.questionsList = QuestionResp?.data;
                    this.questionType = [];
                    QuestionResp?.data.forEach((question: any) => {
                        // Add the current question to allQuestions
                        this.dynamicQuestions.push(question);
                        // Recursively extract dependent questions and add them to allQuestions
                        this.dynamicQuestions.push(...this.extractDependentQuestions(question));
                    });

                    this.dynamicQuestion();
                }
                this.isShow = true;
                this.spinner.hide();
            }
            else {
                this.spinner.hide();
                this.isShow = true;
            }

        }),
            (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.messageDetail.message, 'Error');
            };
    }



    dynamicQuestion() {

        // Dynamically create form controls based on fetched questions
        this.questionsList.forEach((question: any, index: number) => {
            if (question.dependentOnOtherQue == false) {
                if (!this.registrationFormData) {

                    this.questionType.push(question);
                    // if (question.choices?.length && question.type.label === 'Multiple choice') {
                    //   let tempChoices: any[] = [];
                    //   question?.choices?.forEach((choice: any) => {
                    //     let obj = {
                    //       choiceName: choice, isShow: true
                    //     };
                    //     tempChoices?.push(obj)
                    //   });
                    //   this.questionType[index].choices = tempChoices;
                    // }
                    const validators = question ? [Validators.required] : null;
                    let defaultValue;
                    if (question.type.label === 'Multiple choice') {

                        defaultValue = question.type.label === 'Multiple choice' ? [] : '';
                    }
                    if (question.type.label === 'Boolean (yes/no)') {

                        defaultValue = question.type.label === 'Boolean (yes/no)' ? 'No' : 'No';
                        // let x ={
                        //     value : 'No'
                        // }
                        // this.checkDependent(x , question);
                    }
                    this.registerEventForm.addControl(
                        question.customRegistrationFieldId,
                        this.fb.control(defaultValue, validators)
                    );
                }


                if (this.registrationFormData) {

                    Object.keys(this.registrationFormData).forEach((key) => {
                        let control = this.registerEventForm.get(`${key}`);
                        if (!control) {
                            this.registerEventForm.addControl(
                                key,
                                this.fb.control(null)
                            );
                            if (!this.questionType.find(que => que.customRegistrationFieldId == key)) {
                                if (this.dynamicQuestions.find(que => que.customRegistrationFieldId == key)) {
                                    let tempQuestion = this.dynamicQuestions.find(que => que.customRegistrationFieldId == key);
                                    this.questionType.push(JSON.parse(JSON.stringify(this.dynamicQuestions.find(que => que.customRegistrationFieldId == key))));
                                    // let tempChoices = [];
                                    //-------------------------------------------------------------



                                    // if (tempQuestion.choices?.length && tempQuestion.type.label === 'Multiple choice') {

                                    //   let tempChoices: any[] = [];
                                    //   tempQuestion?.choices?.forEach((choice: any) => {
                                    //     let obj = {
                                    //       choiceName: choice, isShow: true
                                    //     };
                                    //     tempChoices.push(obj)
                                    //   });
                                    //   this.questionType[this.questionType.length - 1].choices = tempChoices;
                                    // }
                                }
                            }
                        }
                    })
                    // Assuming your response structure is { key: value }
                    for (const key in this.registrationFormData) {
                        if (this.registrationFormData.hasOwnProperty(key)) {
                            const value = this.registrationFormData[key];

                            // Assuming key is the questionId
                            this.registerEventForm.patchValue({
                                [key]: value
                            });
                        }
                    }
                }
            }
        });
    }
    // Define a function to recursively extract dependent questions
    extractDependentQuestions(question: any): any[] {
        const result: any[] = [];

        // If the question has dependentQuestionLists
        if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
            // Iterate over dependentQuestionLists
            question.dependentQuestionLists.forEach((dependentQuestion: any) => {
                // Clone the original question and replace its dependentQuestionLists with an empty array
                const clonedQuestion = JSON.parse(JSON.stringify(question));
                clonedQuestion.dependentQuestionLists = [];
                // Add the dependent question to the result
                result.push(dependentQuestion);
                // Recursively extract dependent questions of the current dependent question
                result.push(...this.extractDependentQuestions(dependentQuestion));
            });
        }

        return result;
    }

    checkDependent(x: any, data: any) {
        debugger
        if (data?.dependentQuestionLists?.length > 0) {
            let value = [];
            // if(data.type.label === 'Multiple choice'){
               
            //     value =  data.dependentQuestionLists.filter((data:any)=> data.showOptionValue.toLowerCase() == x.value[0].toLowerCase());
            // }
            // else{
                value =  data.dependentQuestionLists.filter((data:any)=> data.showOptionValue.toLowerCase() == x.value.toLowerCase());
            // }
                //if (x.value.toLowerCase() === data.dependentQuestionLists[0].showOptionValue.toLowerCase()) {
                    value.forEach((question: any, index: number) => {
                        this.questionType.push(question);
                        // if (question.choices?.length && question.type.label === 'Multiple choice') {
                        //   let tempChoices: any[] = [];
                        //   question?.choices?.forEach((choice: any) => {
                        //     let obj = {
                        //       choiceName: choice, isShow: true
                        //     };
                        //     tempChoices?.push(obj)
                        //   });
                        //   this.questionType[index].choices = tempChoices;
                        // }
                        const validators = question ? [Validators.required] : null;
                        let defaultValue;
                        if (question.type.label === 'Multiple choice') {
    
                            defaultValue = question.type.label === 'Multiple choice' ? [] : '';
                        }
                        if (question.type.label === 'Boolean (yes/no)') {debugger
    
                            defaultValue = question.type.label === 'Boolean (yes/no)' ? 'No' : 'No';
                            // let x = {
                            //     value: 'No'
                            // }
                            // this.checkDependent(x, question)
                           
                        }
                        this.registerEventForm.addControl(
                            question.customRegistrationFieldId,
                            this.fb.control(defaultValue, validators)
                        );
                        if (this.registrationFormData) {
                            // Assuming your response structure is { key: value }
                            for (const key in this.registrationFormData) {
                                if (this.registrationFormData.hasOwnProperty(key)) {
                                    const value = this.registrationFormData[key];
    
                                    // Assuming key is the questionId
                                    this.registerEventForm.patchValue({
                                        [key]: value
                                    });
                                }
                            }
    
                        }
    
                    })
               // }
                // else {
                //     // this.removeDependentQuestions(data);
              this.removeDependentQuestions(x.value,data.dependentQuestionLists,data);
                // }
    
               // console.log(value);

            
        }
    }

    // removeDependentQuestions(selectedValue: any ,questionList: any[]) {
    //     debugger
    //     questionList.forEach((question: any, idx: number) => {
    //         // Check if the current question has dependent questions
    //         if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
    //             // Recursively remove dependent questions
    //             this.removeDependentQuestions(selectedValue,question.dependentQuestionLists);
    //         }

    //         // Check if the current question is present in the questionType array
    //         let notNullDta = this.questionType.filter(cy => cy.showOptionValue != null);
    //         let questionIndex = notNullDta.find(indexData => (indexData?.showOptionValue?.toLowerCase()) != selectedValue.toLowerCase());
    //         if (questionIndex > -1) {
    //             // Remove the control from the form
    //             this.registerEventForm.removeControl(question.questionDd);

    //             // Remove the question from the questionType array
    //             this.questionType.splice(questionIndex, 1);
    //         }
    //     });

    // }


    removeDependentQuestions(selectedValue: string, questionList: any[], selectedQuestion:any) {
        questionList.forEach((question: any, idx: number) => {
            // Check if the current question has dependent questions
            if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
                // Recursively remove dependent questions
                this.removeDependentQuestions(selectedValue, question.dependentQuestionLists,selectedQuestion);
            }

            // Check if the current question's showOptionValue does not match the selectedValue
            if (question.showOptionValue && question.showOptionValue.toLowerCase() !== selectedValue.toLowerCase()) {
                // Remove the control from the form
                if (this.registerEventForm.get(question.customRegistrationFieldId)) {
                    this.registerEventForm.removeControl(question.customRegistrationFieldId);
                }

                // Remove the question from the questionType array
                const questionIndex = this.questionType.findIndex(q => q.customRegistrationFieldId === question.customRegistrationFieldId);
                if (questionIndex !== -1) {
                    this.questionType.splice(questionIndex, 1);
                }
            }
        });
    }

    openDialog(): void {
        this.dialogRef = this._dialog.open(SeatDialogComponent, {
            disableClose: true,
            width: '900px',
            height: '550px'
        })
    }
    close() {
        this.dialogRef.close(true)
    }

    updatePrice() {
        this.selectedOption
    }
    passIdOfPasses(val: any, passData: any) {
        this.isSubmitted = true;
        this.passBySessionId = passData.passId;
        this.selectPassPrice = passData.passPrice;
        this.passName = passData.passName;
        this.codeOfPromoCode = passData.codeOfPromoCode;
        this.applyPromoCodeId = passData.promoCodeId;
        this.percentOfDiscountOfPromoCode = passData.percentOfDiscountOfPromoCode
        localStorage.setItem('codeOfPromoCode', this.codeOfPromoCode);
        localStorage.setItem('selectPassPrice', this.selectPassPrice);
        localStorage.setItem('percentOfDiscountOfPromoCode', this.percentOfDiscountOfPromoCode);
        localStorage.setItem('passName', this.passName);
        this.commonService.passDataObj.next(passData)

        this.GetSessionByEventIdAndPassId(this.passBySessionId)

    }

    //############################### API of get Pass/ Ticket iformation by event id #########################
    GetPassInformationByEventId() {
        this.eventService.GetPassInformationByEventId(this.eventDetailsId).subscribe((passresponce: any) => {
            if (passresponce.isSuccessful == true) {
                this.selectedPassList = passresponce.data
            } else {
                this.spinner.hide();
            }
        }),
            (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.messageDetail.message, 'Error');
            };
    }

    GetSessionByEventIdAndPassId(pass: any) {
        this.isSubmitted = true;
        let passId = pass;
        this.eventService.GetSessionByEventIdAndPassId(passId, this.eventDetailsId).subscribe((sessionResp: any) => {
            if (sessionResp.isSuccessful === true)//success
            {
                this.sessionData = sessionResp.data;
                const selectedSessions = this.sessionData.map((session: any) => session.sessionId);

                this.registerEventForm.get('selectSession')?.setValue(selectedSessions);
                //this.registerEventForm.setValidators([Validators.required]);
                this.registerEventForm.updateValueAndValidity();
                this.isSubmitted = false;

            } else {
                // this.registerEventForm.setValidators(null);
                this.registerEventForm.setValue(null);
                this.registerEventForm.updateValueAndValidity();
                this.spinner.hide();
                this.isSubmitted = false;
            }
        }),
            (error: any) => {
                this.spinner.hide();
                this.isSubmitted = false;
                this.toaster.error(error.messageDetail.message, 'Error');
            };
    }

    //...............form Submit..............................................
    submit(form: FormGroup) {

        this.spinner.show();
        this.submitted = true;
        if (form.invalid) {
            this.markFormGroupTouched(this.registerEventForm);
            this.spinner.hide();
            return;
        }
        let registrationmodeLabel = '';
        if (this.registerEventForm.get('registrationMode').value == '1') {
            registrationmodeLabel = 'Online'

        }
        else if (this.registerEventForm.get('registrationMode').value == '2') {
            registrationmodeLabel = 'Offline'
        }

        let regModeObj = {
            value: parseInt(this.registerEventForm.get('registrationMode').value),
            label: registrationmodeLabel
        }

        // send submit form to BehaviorSubject
        this.commonService.registrationSubmitData.next(form.value)
        let keyArray = Object.keys(this.registerEventForm.value);
        let customQuestionList: any = [];
        let dummyCustomQuestionList: any = [];
        keyArray.forEach((x: any) => {
            let exists = this.questionType.find((data: any) => data.customRegistrationFieldId === x);
            if (exists) {
                let id = exists.customRegistrationQueId
                let valueToAssign = this.registerEventForm.get(`${x}`)?.value;
                let obj = {
                    customQuestionId: x,
                    response: valueToAssign
                };
                let obj1 = {
                    customQuestionId: id,
                    response: valueToAssign
                }
                customQuestionList.push(obj);
                dummyCustomQuestionList.push(obj1)
            }
        });
        // Deep copy of questionList for Obj
        let copiedQuestionListForObj = JSON.parse(JSON.stringify(customQuestionList));

        // Deep copy of questionList for registrationObj
        let copiedQuestionListForRegistration = JSON.parse(JSON.stringify(dummyCustomQuestionList));

        // Now modify copiedQuestionListForRegistration
        copiedQuestionListForRegistration.forEach((x: any) => {
            if (Array.isArray(x.response)) {
                x.response = x.response.join(', ');
            }
        });
        let Obj = {
            firstName: this.registerEventForm.value.firstName,
            lastName: this.registerEventForm.value.lastName,
            email: this.registerEventForm.value.email,
            mobileNumber: this.registerEventForm.value.mobileNumber,
            eventId: this.eventDetailsId,
            passId: this.registerEventForm.value.passId,
            sessionLists: this.registerEventForm?.value?.selectSession?.map((element: any) => element = { 'sessionId': element }),
            seatnumber: this.registerEventForm.value.seatnumber || '',
            applyPromoCode: this.codeOfPromoCode,
            applyPromoCodeId: this.applyPromoCodeId,
            customQuestionLists: copiedQuestionListForObj,
            isAttendee: this.registerEventForm.value.isAttendee,
            isGroupRegistration: false,
            attendeeLists: [
                null
            ],
            registrationMode: regModeObj
        }
        // console.log('obj', Obj)


        let registrationObj = {
            firstName: this.registerEventForm.value.firstName,
            lastName: this.registerEventForm.value.lastName,
            email: this.registerEventForm.value.email,
            mobileNumber: this.registerEventForm.value.mobileNumber,
            eventId: this.eventDetailsId,
            passId: this.registerEventForm.value.passId,
            sessionLists: this.registerEventForm.value.selectSession.map((element: any) => element = { 'sessionId': element }),
            seatnumber: this.registerEventForm.value.seatnumber || '',
            applyPromoCode: this.codeOfPromoCode,
            customQuestionLists: copiedQuestionListForRegistration,
            isAttendee: this.registerEventForm.value.isAttendee,
            applyPromoCodeId: null,
            isGroupRegistration: false,
            attendeeLists: [
                null
            ],
            registrationMode: regModeObj
        }
        // console.log('registrationObj', Obj)

        let createLeadObj = {
            firstName: this.registerEventForm.value.firstName,
            lastName: this.registerEventForm.value.lastName,
            email: this.registerEventForm.value.email,
            mobileNumber: this.registerEventForm.value.mobileNumber,
            eventId: this.eventDetailsId,
        }

        //***********************Post API Of Create Lead Success fully **************************************/    
        this.eventService.CreateLead(createLeadObj).subscribe((leadResp: any) => {
            if (leadResp.isSuccessful == true) {
                this.commonService.groupRegistrationFormSubject.next(Obj);

                this.spinner.hide();
                if (this.value == 'Single') {
                    this.commonService.registrationFormSubject.next(registrationObj);
                    this.router.navigate(['/payment-info/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat, Type: this.data, Token: this.token } });
                    this.spinner.hide();
                }
                else if (this.value == 'Group') {
                    this.router.navigate(['/group-attendee/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat, Type: this.data, Token: this.token } });
                    this.spinner.hide();
                }
            }
            else {
                this.spinner.hide();
            }
        },
            (err: any) => {
                this.spinner.hide();
                this.toaster.error(err.error.messageDetail.message, 'Error');
            }
        );
    }
    markFormGroupTouched(formGroup: FormGroup) {
        Object.values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control instanceof FormGroup) {
                this.markFormGroupTouched(control);
            }
        });
    }

    returnControl(controlName: any) {
        return this.registerEventForm.get(`${controlName}`);
    }
    back() {
        this.registerEventForm.reset()
        this.router.navigate(['/event-details/' + this.eventDetailsId])
    }

}
