<div class="d-flex justify-content-between align-items-center">
  <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Please Wait... </p>
  </ngx-spinner>
</div>

<div class="container-fluid">
  <div class=" row ">
    <div class="col-12 mt-3 text-center">
      <div class="thank-you-container">
        <h1 class="thank-you-text w-50 mx-auto shadow-lg">
          Thank you for registering for this event
          <h2 class="m-0">Your payment has been successfully completed!</h2>
        </h1>
      </div>
      <mat-card class="mb-4 w-50 mx-auto shadow-lg ">
        <div class=" mx-6 my-4">
          <div class="text-justify my-2">
            <span class="fw-bold"> Event Name :</span> {{pymntRspData?.eventName}}
          </div>
          <div class="text-justify my-2">
            <span class="fw-bold">Location:</span> {{pymntRspData?.eventLocation}}
          </div>
          <div class=" text-justify my-2">
            <span class="fw-bold  text-justify"> Date & Time: </span> {{pymntRspData?.eventStartDate | date: 'medium'}}
          </div>
          <div class=" text-justify my-2">
            <span class="fw-bold  text-justify">Owner Name: </span> {{pymntRspData?.customer}}
          </div>
          <div class=" text-justify my-2">
            <span class="fw-bold  text-justify">Owner Email: </span> {{pymntRspData?.customerEmail}}
          </div>
          <div class=" text-justify my-2">
            <span class="fw-bold  text-justify"> Total Price: </span>{{pymntRspData?.totalAmount | currency:
            pymntRspData?.currency : 'symbol'}}
          </div>
          <div class=" text-justify my-2">
            <span class="fw-bold  text-justify"> Reference number: </span> {{pymntRspData?.invoiceId}}
          </div>
          <p class="text-justify my-2 bg-red-100 fw-bold">Note: Your Ticket will be generated soon , We will inform you
            in
            a mail.</p>
        </div>
      </mat-card>
      <div class="d-flex mt-4 mb-4" *ngIf="!data">
        <div class="col-1"></div>
        <div class="col-5 ml-4">
          <a class="text-blue-500 " routerLink="">Browse more events </a>
        </div>
        <div class="col-3 justify-end "> </div>
        <div class="col-3">
        </div>
      </div>
    </div>
  </div>
</div>