import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeCardNumberComponent, StripeService } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { API_Constants } from '../../../services/API_Constants';
import { PaymentService } from '../../../services/PaymentService';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { NgxSpinner, NgxSpinnerService, Spinner } from 'ngx-spinner';
import { EventsService } from 'src/services/events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent {
  paymentForm!: FormGroup;
  elements!: StripeElements;
  card!: StripeCardElement;
  @ViewChild('cardElement')
  cardElement!: ElementRef<any>;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: 'blue',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#CFD7E0'
        },
        // Add a border to the card element
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${API_Constants.stripeKey}`
  });

  @ViewChild(StripeCardNumberComponent)
  card1!: StripeCardNumberComponent;
  amount: any;
  tokenObj: any;
  paymentId: any;
  eventDetailsId: any;
  registrationFormData: any;
  price: any;
  invoiceId: any;
  currencyAUD: any;
  value: any;
  seat: any;
  data: any;
  eventDetail: any;
  ticketPrice: any;
  token: any;
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private eventService: EventsService,
    private toaster: ToastrService

  ) {

    this.eventDetailsId = this.route.snapshot.paramMap.get('id');
    this.value = this.route.snapshot.queryParamMap.get('value');
    this.data = this.route.snapshot.queryParamMap.get('Type');
    this.token = this.route.snapshot.queryParamMap.get('Token');
    // this.seat = parseInt(this.route.snapshot.queryParamMap.get('seat'));
    this.price = this.route.snapshot.queryParamMap.get('totalPrice1');
    let obj = {
      data: this.data,
      token: this.token
    }
    const isClaimed = sessionStorage.getItem('isClaimed');
    const isCrm = sessionStorage.getItem('isCrm');

    if ((isClaimed != '0') && (isCrm != '0')) {
      this.router.navigate(['/unauthorized']);
    }
    this.commonService.crmUrlData.next(obj);
    this.commonService.eventDetail.subscribe((eventresp: any) => {
      this.eventDetail = eventresp;
      this.currencyAUD = eventresp?.currency ? eventresp?.currency : 'AUD'
    });
    this.GetEventDetailsByEventByEventId();

  }

  ngOnInit() {
    this.GetEventDetailsByEventByEventId();
    this.paymentForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      amount: [null],
      currency: [null]
    });


  }

  GetEventDetailsByEventByEventId() {
    this.spinner.show();
    this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
      if (respEventDetails.isSuccessful === true) {
        this.seat = respEventDetails?.data?.eventSeatAvailable;
        this.spinner.hide();

        this.stripeService.elements(this.elementsOptions)
          .subscribe(elements => {
            this.elements = elements;
            // Only mount the element the first time
            if (!this.card) {
              this.card = this.elements.create('card', this.cardOptions);
              this.card.mount('#card-element');
            }
          });

        this.getCardDetails();
      }
      else {
        this.spinner.hide();
        this.stripeService.elements(this.elementsOptions)
          .subscribe(elements => {
            this.elements = elements;
            // Only mount the element the first time
            if (!this.card) {
              this.card = this.elements.create('card', this.cardOptions);
              this.card.mount('#card-element');
            }
          });

        this.getCardDetails();
      }
    })
    this.spinner.hide();
  }

  getCardDetails() {
    this.commonService.registrationFormSubject.subscribe(resp => {
      if (resp) {
        if (resp.promoCodeApplied == false) {
          resp.applyPromoCodeId = null;
          resp.applyPromoCode = null;
        }
        this.registrationFormData = resp;
        this.amount = resp;
        this.paymentForm.patchValue({
          name: resp.firstName,
          email: resp.email,
          amount: this.price,
          currency: this.currencyAUD
        });
        this.spinner.hide();
      }
    });
    this.spinner.hide();
  }

  OnSubmitMakePayment(): void {
    this.spinner.show();
    if (this.paymentForm.valid) {
      this.stripeService.createToken(this.card)
        .subscribe((result) => {
          if (result.token) {
            // Use the token
            this.tokenObj = result.token;
            this.createCustomer(result);
          } else if (result.error) {
            // Error creating the token
            this.toaster.error("Something went wrong.", result.error.message)
            this.spinner.hide();
          }
        });
    } else {
      this.toaster.error("Payment Invalid", 'Error');
      this.spinner.hide();
    }
  }

  createCustomer(cardDetail: any) {
    let body = new URLSearchParams();
    body.set('email', this.paymentForm.value.email);
    body.set('name', this.paymentForm.value.name);
    return this.http.post<any>(API_Constants.customerURL, body.toString(), { headers: this.reqHeader })
      .subscribe((result) => {
        if (result) {
          this.paymentIntents(result.id);
        }
      });
  }

  paymentIntents(customerid: any) {
    let body = new URLSearchParams();
    let amount = this.paymentForm.value.amount * 100
    body.set('amount', amount.toString());
    body.set('currency', this.paymentForm.value.currency);
    body.set('customer', customerid);
    body.set('payment_method_types[]', "card");
    return this.http.post<any>(API_Constants.paymentIntentURL, body.toString(), { headers: this.reqHeader })
      .subscribe((result) => {
        if (result) {
          this.confirmCardPaymentMethod(result.client_secret);
        }
        else {
          this.spinner.hide();
        }
      });
    this.spinner.hide();
  }

  confirmCardPaymentMethod(client_secret: any) {
    this.stripeService.confirmCardPayment(client_secret, {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.paymentForm?.get('name')?.value,
        },
      },
    }).subscribe((result) => {
      if (result?.paymentIntent?.status === 'succeeded') {
        this.updatePaymentDetails(result?.paymentIntent)
      }
      else {
        this.router.navigate(['/error'], {
          queryParams: {
            errorCode: result.error?.code,
            errorMessage: result.error?.message,
          },
        });
        this.spinner.hide();
      }
    });
  }
  updatePaymentDetails(result: any) {
    this.spinner.show();
    if ( this.seat > 0  || this.token) {
      this.eventService.CreateEventRegistration(this.registrationFormData).subscribe((registrationresponce: any) => {

        if (registrationresponce.isSuccessful == true) {
          if (registrationresponce.data && registrationresponce.data.invoiceId) {

            this.invoiceId = registrationresponce.data.invoiceId;
            const body = {
              invoiceId: this.invoiceId,
              stripeReferenceId: result.payment_method,
              stripePaymentId: result.id,
              stripePaymentDate: new Date(),
              stripeSyncType: this.tokenObj?.card?.brand
            }
            this.paymentService.updatePaymentDetails(body).subscribe((result) => {
              if (result.isSuccessful == true) {

                this.router.navigate(['/thank-you'], {
                  queryParams: {
                    PaymentReference: this.tokenObj?.card?.brand,
                    Amount: this.price,
                    Currency: 'inr',
                    Status: result.status,
                    InvoiceId: this.invoiceId,
                    Type: this.data
                  },
                });
                this.toaster.success(registrationresponce.messageDetail.message, 'Confirmation', {
                });
                this.spinner.hide();
              }
              else {
                this.spinner.hide();
              }
            }),
              (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.messageDetail.message, 'Error');
              };;
          }
        }
        else if (registrationresponce.isSuccessful == false) {
          this.spinner.hide();
          this.toaster.error(registrationresponce.messageDetail.message, 'Error', {
          });
        }
      }),
        (error: any) => {
          this.spinner.hide();
          this.toaster.error(error.messageDetail.message, 'Error');
        };;
    }
    else {
      this.spinner.hide();
      this.toaster.error("Seats are not available for this Event.", 'Error')
    }
  }
  back() {
    this.router.navigate(['/payment-info/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
  }
}



