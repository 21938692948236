import { API_Constants } from '../services/API_Constants';
// import { PaymentDetailsRequest } from './../models/payment_details_request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { TokenizationRequestViewModel } from '../models/tokenization_request_viewmodel';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }

  private cardDetails = new BehaviorSubject<any>(null);
  latestCardDetails = this.cardDetails.asObservable();

  getPaymentDetailsForAdhoc(paymentId: string
  ): Observable<any> {
    const url = API_Constants.baseURL + API_Constants.getPaymentDetailsForAdHoc + paymentId;
    return this.httpClient.get<any>(
      url,
    );
  }

  updatePaymentDetails(body: any): Observable<any> {
    const url = environment.apiURL + API_Constants.updatePaymentDetails;
    return this.httpClient.post<any>(
      url,
      body,
      this.httpOptions
    );
  }

  updateCardDetails(card: any) {
    this.cardDetails.next(card);
  }
}
